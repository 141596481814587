.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
  }

  .card {
    
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 10px;
    width: 30%;
  }
  
  .modal {
    position: fixed;
    margin: 0,auto;
    top: 30vh;
    left: 10%;
    width: 30%;
    z-index: 100;
    overflow: hidden;
    background:white;
  }
  
  .header {
    background: #4f005f;
    padding: 1rem;
  }
  
  .header h2 {
    margin: 0;
    color: white;
  }
  
  .content {
    padding: 1rem;
    
  }
  
  .actions {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
  }
  
  @media (min-width: 768px) {
    .modal {
        position: fixed;
        margin: 0,auto;
        top: 41vh;
        
        width: 30%;
        
        overflow: hidden;
        background:lightgray;
      }

      .card {
    
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
        border-radius: 10px;
        width: 30%;
      }
  }