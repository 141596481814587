.button {
  /* width: 3rem; */
  margin: 0 0.5rem;
  background-color: #8e24aa;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.button a {
  display: flex;
  justify-content: center;
  color: white;
  text-decoration: none;
}

.button:hover {
  background-color: #0d6efd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}
